import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "A Beginners Guide to Knowing Nothing About PC Building",
  "date": "2020-11-18",
  "slug": "blog/a-beginners-guide-to-knowing-nothing-about-pc-building",
  "featuredImage": "./images/flight-sim-1.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`By: Steve (ecto1321)`}</strong></p>
    <h2>{`Part One`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"The more I learn, the more I realize how much I don't know." -Albert Einstein`}</p>
    </blockquote>
    <p>{`It all started with a trip to Microcenter…`}</p>
    <p>{`Well not exactly. I guess it started when I heard that Microsoft and Asobo were going to be releasing their first update to the Flight Simulator franchise in fourteen years. I was a Flight Simulator nut as a kid. I remember sitting at my family’s Gateway computer, hooked into our top of the line 56.6k modem and playing Flight Simulator ‘98 for hours at a time. I was thirteen years old and I was piloting a Learjet from New York to Vegas, making “announcements” to the passengers in the back when appropriate. My parents encouraged me to go out and play with the other kids in the neighborhood, but my home was up there in the clouds…`}</p>
    <p>{`Fast forward to 2020. With a global pandemic severely limiting travel and long periods of lockdown throughout the country, it was an opportune time for Microsoft to revive a franchise that lets gamers travel the world from the comfort and safety of home. I might have had to cancel my trip to Croatia, but I could fly over Dubrovnik and admire the city’s ancient walls. There was just one question; would my aging, but still very capable desktop, be up to the task? `}</p>
    <p>{`When I bought my current Asus pre-built gaming desktop, I targeted a system that would allow me to run the newly released Oculus Rift. I had never been a big gamer, but new technology excites me, and this seemed like a big one. With a fairly basic understanding of desktop technology at the time, I selected a system with a 6th Gen Intel Core i7 processor and an Nvidia GeForce 980 GPU, pretty solid specs for 2016. And it has aged very well. While multitasking and working from home, my trusty Asus has powered through just about any task I throw it’s way. Sure, loading previews in Lightroom can take a while, and my first couple forays into using 3D modeling software were less than ideal, but for the most part, it keeps up. Or it did...until I installed Microsoft Flight Simulator 2020 and took to the skies. `}</p>
    <p>{`Taking to the skies is probably an over statement. Just loading the game to the home screen took several minutes. Trying to load a flight was unbearable. I wasn’t ready to invest in a whole new system, but I knew an SSD could improve load times, so I started researching what could fit in my Asus’ miniaturized chassis. Apparently, the motherboard had a slot for an M.2 SSD, but I had never even heard of an M.2 drive, no less cracked open a case to install one. I immediately realized I had been out of the tech game for too long and some research was in order. Browsing the forums, it seemed people had some success getting Samsung M.2 drives to work in the aging motherboard, so with some trepidation, I jumped on Amazon and ordered one. `}</p>
    <p>{`Installation was fairly straight forward. I unscrewed a couple screws, slid off the side of the case, removed the GPU and CPU cooler, and found the M.2 slot. Wow, PC modding is kind of easy, I thought to myself. This stuff just kind of all snaps together.
Well not exactly, turns out I was missing a screw to hold the new M.2 drive down to the board. My computer would have to remain splayed out and vulnerable for at least another day while I tracked down the necessary screw. I was disappointed and a little afraid of leaving my PC in such a state, but I remained cautiously optimistic I would have it working the next day.`}</p>
    <p>{`The next day, I took a drive out to the Brooklyn Microcenter. I had never actually been in a Microcenter before, but I heard they were like a candy store for computer nerds, and as an aspiring computer nerd, I figured I’d give it a shot. Walking in the doors, I immediately knew I was in the right place. I walked confidently past the pre-built and laptop displays (I was a PC modder now after all...) and headed straight for the DIY section of the store... `}</p>
    <p>{`Now, this would be a good time to explain something about me… I like to do my own research. Before I go into any store, I’ve usually spent several days or weeks researching the product I intend to buy. I hate dealing with salespeople who usually know less about a product than I do and I take a special kind of pride in correcting them when they’re wrong about a product. Actually, I guess I’m kind of a jerk. I also don’t like admitting that I don’t know something. My wife would probably tell you that I’m that stereotypical guy that won’t pull over and ask for directions when he’s lost. I’m not proud of it...`}</p>
    <p>{`I was immediately transfixed by all the tech I was surrounded with. PC cases, power supplies, GPUs, CPUs, motherboards. The one thing I didn’t see was that one little screw I needed to finish installing my new M.2 drive. Because of COVID, the store was pretty empty save for a skeleton crew of employees that were working the floor. Then I heard it... the siren call of two computer nerds arguing about whether Batman was responsible for the escalation of crime in Gotham City. It seemed I had found my people…`}</p>
    <p>{`Following the sound of the spirited argument and sucking up my pride, I approached the DIY desk where I found two salesmen, Daniel and Justin. “He was only doing what was necessary to fight crime while also protecting his anonymity,” I interjected. The ice was immediately broken. I told Daniel and Justin about my plan to improve performance in Flight Simulator. They agreed that the SSD drive would help with load times and showed me exactly where I could find the necessary screw. `}</p>
    <p>{`When Daniel noticed me admiring the shiney tempered glass cases, he asked if I had ever considered building my own PC. “I would love to, but I don’t think I really know enough about computers these days,” I admitted somewhat shamefully. `}</p>
    <p>{`“Well, do you like legos and can you read an instruction manual?” he asked.`}</p>
    <p>{`He had me at legos...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      